import dayjs from "dayjs";

const useTaoDepositStreak = ({ immediate = true }: { immediate?: boolean } = {}) => {
	const isGuest = useIsGuest();
	const { close } = useTaoModals();
	const { data: appInit } = useAppInitData();
	const { refresh: refreshHomeData } = useHomePage();
	const { refresh: refreshPromotionsData } = usePromotionsData({ immediate: false });
	const { data: depositInfoData, refresh } = useGetDepositStreakData({
		immediate: immediate && appInit.value?.depositStreak?.isActive
	});

	const { durationLeft, reset } = useCountdown({
		timestamp: depositInfoData.value.finishedAt ?? "",
		formatToken:
			dayjs(depositInfoData.value.finishedAt ?? "").diff(dayjs(), "d") >= 1
				? "DD[d ] HH[h ]mm[m ]"
				: "HH[h ]mm[m ]ss[s ]",
		onCountdownStop: () => {
			if (appInit.value?.depositStreak?.isActive && !isGuest.value) {
				appInit.value.depositStreak.isActive = false;
				Promise.all([refreshPromotionsData(), refreshHomeData()]);
				close("LazyOModalDepositStreak");
			}
		}
	});

	const { durationLeft: durationLeftResetStreak, reset: resetDurationStreak } = useCountdown({
		timestamp: depositInfoData.value?.resetStreakAt ?? "",
		formatToken: "HH[h ]mm[m ]ss[s ]",
		onCountdownStop: !isGuest.value ? refresh : () => {}
	});

	watch(
		() => depositInfoData.value.finishedAt,
		(value) => {
			reset(value || "");
		}
	);

	watch(
		() => depositInfoData.value.resetStreakAt,
		(value) => {
			resetDurationStreak(value || "");
		}
	);

	return { depositInfoData, durationLeft, durationLeftResetStreak, refresh };
};

export default useTaoDepositStreak;
